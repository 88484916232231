//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

html {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900
}

.font-secondary {
    font-family: 'Caveat', cursive;
    font-weight: 300
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important
}

.img-responsive {
    display: block;
    width: 100%;
}

.text-center {
    text-align: center
}

.text-white {
    color: white;
}

.page-title {
    font-weight: 900;
    line-height: 25px;
    font-size: 26px;
    margin-bottom: 40px;
}
.page-Produtos .page-title {
    margin-top: 50px;
}

.page-title small {
    display: block;
    font-size: 30px
}

.container {
    padding-left: 16px;
    padding-right: 16px;
    width: 1000px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

img,
a {
    display: inline-block
}

a,
.btn {
    transition: all .3s ease;
}

body {
    padding-top: 130px;
}


.btn {
    display: inline-block;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    font-weight: 900;
    color: white;
    padding: 13px 20px;
    text-decoration: none;
    border: 0;
    border: 2px solid transparent
}

.btn:hover {
    border-color: black;
}

.btn--white,
.btn:hover {
    color: black;
    background-color: rgba(255, 255, 255, 1);
}

.btn--white:hover {
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.btn--outline {
    border: 2px solid black;
    background-color: transparent;
    color: black;
}

.btn--outline:hover {
    color: white;
    background-color: black;
    border: 2px solid white;
}

.title {
    font-size: 40px;
    font-weight: 900;
}

.title .font-secondary {
    display: block;
    font-size: 90%
}

/************************ header ****************************/
.header {
    background-color: black;
    height: 130px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    transition: all .4s ease;
}

.header__open,
.header__close {
    display: none;
}

.header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.header__logo {
    height: 90px;
}

.header__logo--sticky {
    display: none;
}

.header__menu {
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 10px;
    font-size: 14px;
    list-style: none;
    display: inline-block;
    position: relative;
}

.header__submenu {
    position: absolute;
    top: 99%;
    background: white;
    left: 50%;
    min-width: 200px;
    transform: translate(-50%, -30px);
    padding: 25px 35px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, .8);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear .4s, opacity .3s ease, transform .4s ease;
}

.header__menu:hover .header__submenu {
    opacity: 1;
    visibility: visible;
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
    transition: visibility 0s linear .0s, opacity .3s ease, transform .4s ease;
}

.header__submenu a {
    text-transform: none;
    color: black;
    text-decoration: none;
    padding: 10px 0;
    display: block;
    transition: all .4s ease
}

.header__submenu a.active,
.header__submenu a:hover {
    color: #15185d
}

.header.sticky {
    height: 60px;
}

.header.sticky .header__logo {
    display: none;
}

.header.sticky .header__logo--sticky {
    display: block;
    height: 35px;
}

/************************ BANNER ****************************/


.banner__detail {
    width: 100%;
    height: 75px;
    overflow: hidden;
}

.banner__detail img {
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center;
}

.banner {
    background-color: black;
    height: calc(100vh - 205px);
    position: relative;
    z-index: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.banner__img {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.banner .swiper-slide {
    display: flex;
    align-items: center
}

.banner__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 550px;

}

.banner__call {
    flex: 0 0 100%;
    font-size: 24px;
    font-weight: 900;
    color: white;
    background-color: #d79313;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 20px;
    padding-right: 20px;
}

.banner__content .btn {
    font-size: 14px;
    padding: 23px 40px;
    height: 63px
}

.banner__content .btn--white {
    font-size: 28px;
    padding-top: 14px
}

.banner .swiper-button-next,
.banner .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 35px 20px;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 0 !important;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 0 !important;
}

/************************ uma vila ****************************/

.vila {
    display: flex;
    padding-top: 150px;
    padding-bottom: 0px
}

.vila__left,
.vila__right {
    flex: 0 0 50%;
}

.vila__right {
    line-height: 170%
}

.vila__carousel {
    padding-top: 200px;
    background-image: url(../img/bg_vila.jpg);
    //background-position: bottom center;
    background-repeat: no-repeat;
    //background-size: cover;
    padding-bottom: 90px;
    background-position: 50% top;

    img {
        width: 100%;
    }
}

.vila__img {
    height: 540px;
    width: 100%;
    display: block;
    object-fit: cover
}

/************************ swiper bullets ****************************/


.swiper-bullets .swiper-pagination {
    width: 100%;
    position: relative;
    margin-top: 10px;
    z-index: 1;
}

.swiper-bullets .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border: 3px solid white;
    margin: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #d79313;
    opacity: 1;
    transition: all .3s ease;
}

.swiper-bullets .swiper-pagination-bullet-active {
    background-color: #15185d
}

/************************ excelencia ****************************/

.excelencia {
    background-image: url(../img/banner_excelencia-nos-detalhes.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    min-height: 570px;
    display: flex;
    align-items: center;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.excelencia .container {
    display: flex;
    justify-content: flex-end
}

.excelencia__content {
    width: 39%;
}

.excelencia__content .title {
    margin-bottom: 40px;
}

.excelencia__text {
    line-height: 170%;
    text-shadow: 1px 1px 1px black
}

.excelencia__text .btn {
    text-shadow: none;
}


/************************ DESTAQUE ****************************/

.destaque {
    background-image: url(../img/bg_destaques.jpg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 70px
}

.destaque .container {
    display: flex;
    padding-top: 150px;
    padding-bottom: 0px
}

.destaque__left,
.destaque__right {
    flex: 0 0 50%;
}

.destaque__right {
    line-height: 170%
}

.destaque-swiper.swiper-container {
    margin-top: 60px
}

.destaque-swiper__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 196px;
    height: 732px;
    object-fit: contain;
    position: relative;
    z-index: 1
}

.destaque-swiper__detail {
    border-top: 1px solid #15185d;
    color: #15185d;
    position: absolute;
    padding-top: 10px
}

.destaque-swiper__detail--first {
    left: 200px;
    top: 390px;
    width: 300px;
    padding-right: 85px;
}

.destaque-swiper__detail--second {
    left: 100px;
    top: 500px;
    width: 400px;
    padding-right: 85px;
}

.destaque-swiper__detail--third {
    right: 100px;
    top: 500px;
    text-align: right;
    width: 400px;
    padding-left: 85px;
}

.destaque-swiper__content {
    position: absolute;
    padding-top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%)
}

.destaque-swiper__title--left,
.destaque-swiper__title--right {
    border-bottom: 1px solid #15185d;
    color: #15185d;
    bottom: 520px;
    position: absolute;
}

.destaque-swiper__title--left {
    width: 330px;
    font-size: 40px;
    left: 140px;
    padding-bottom: 8px;
}

.destaque-swiper__title--right {
    font-size: 42px;
    font-weight: 900;
    right: 100px;
    width: 370px;
    text-align: right;
}

/************************ FOOTER ****************************/

.footer {
    background: black;
    color: white;
    padding-top: 30px;
    padding-bottom: 70px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: relative;
}

.footer__logo {
    margin-bottom: 70px;
    position: relative;
    width: 95%;
    margin-left: auto;
    margin-right: auto
}

.footer__logo::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    background: white;
    height: 1px;

}

.footer__logo img {
    width: 70px;
    height: 35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    z-index: 1;
    position: relative;
}

.footer__left {
    flex: 0 0 210px;
}

.footer__right {
    flex: 0 0 calc(100% - 210px);
}

.footer__somos {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 20px
}

.footer__rede {
    color: white;
    text-decoration: none;
    margin-bottom: 18px;
    display: block
}

.footer__rede i {
    width: 25px
}

.footer__right {
    display: flex;
    justify-content: flex-end;
}

.footer__menu {
    text-align: center;
    padding: 0 16px;
    margin-bottom: 35px;
}

.footer__link {
    color: white;
    text-decoration: none;
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
}

.footer__link--sub {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 7px
}

.footer .container {
    display: flex;
    justify-content: space-between
}

.footer__detail {
    width: 100%;
    height: 75px;
    overflow: hidden;
}

.footer__detail img {
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center;
}

/************************ MODAL ****************************/

.modal__close {
    border-radius: 50%;
    background: #d79313;
    color: white;
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 0;
    font-size: 23px;
    padding-bottom: 5px;
    position: absolute;
    right: -10px;
    top: -10px;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.5);
    visibility: hidden;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    transform: scale(1.5);
    transition: visibility 0s linear .4s, opacity .3s ease, transform .4s ease;
    overflow: auto;
    padding-bottom: 10vw;
}

.modal.active {
    transition: visibility 0s linear .0s, opacity .3s ease, transform .4s ease;
    transform: scale(1);
    opacity: 1;
    visibility: visible
}

.modal__box {
    background: white;
    position: relative;
    width: 500px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8vw;
    padding: 35px;
    line-height: 170%;
    box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.3);
}


.modal__header {
    font-weight: bold;
    margin-bottom: 20px
}

.modal__footer {
    font-size: 11px;
    line-height: 14px;
    margin-top: 35px;
    font-style: italic
}

/************************ form ****************************/
input,
button,
select,
textarea {
    color: inherit;
    font-size: inherit;
    font-family: inherit
}

.form-control {
    position: relative;
    margin-bottom: 10px
}

.form-control--inline {
    display: flex;
    align-items: center;
}

.form-control input,
.form-control textarea {
    background-color: white;
    border: 0;
    resize: none;
    display: block;
    padding: 12px 30px;
    padding-top: 24px;
    width: 100%;
}

.form-control--floating-label label {
    position: absolute;
    top: 16px;
    left: 30px;
    transition: all .3s ease;
    pointer-events: none
}

.form-control--floating-label.active label {
    font-size: 12px;
    top: 7px;
}

.form-control--inline input {
    background-color: #d6d6d6;
    flex: 1 1 auto;
    padding: 15px 15px;
    border: 0;
}

.form-control--inline label {
    flex: 0 0 auto;
    padding-right: 6px;
}

/************************ vinicola ****************************/

.vinicola .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.vinicola .swiper-slide img {
    width: 100%;
}

.page {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url(../img/bg_subcategorias.jpg);
    background-size: 100% auto;
    background-color: #f4f4f4;
    background-repeat: no-repeat;
    background-position: top center;
}

.page p {
    line-height: 160%;
}

.vinicola__informacoes {
    font-size: 18px;
    font-weight: 900
}

.vinicola__left {
    width: 47%;
    line-height: 160%;
}

.vinicola__right {
    width: 47%;
}

.vinicola__mvv {
    margin-top: 40px;
}

.vinicola__mvv h3 {
    margin-bottom: 10px
}

.vinicola__right .swiper-container .swiper-slide img {
    height: 300px;
    width: 100%;
    display: block;
    object-fit: cover;
    margin-bottom: 20px
}

.vinicola__video {
    margin-top: 50px
}

.vinicola__video .font-secondary {
    font-size: 30px;
    margin-bottom: 30px;

}

.vinicola__video iframe {
    width: 100%;
    display: block;
}

.vinicola__item {
    margin-top: 50px;
    line-height: 160%;
}

.vinicola__item h2 {
    color: #15185d;
    font-weight: 900;
    margin-bottom: 20px
}

.vinicola__item-thumb {
    width: 40%;
    flex: 0 0 40%;
}

.vinicola__item-text {
    flex: 0 0 56%;
}

.vinicola__item--half .vinicola__item-thumb {
    width: 47%;
    flex: 0 0 47%;
}

.vinicola__item--half .vinicola__item-text {
    flex: 0 0 47%;
}

.premiacao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px
}

.premiacao__left {
    flex: 0 0 45%;
}

.premiacao__left img {
    max-width: 100%;
    display: block;
    object-fit: contain;
}

.premiacao__title {
    font-size: 20px;
    font-weight: 900;
    color: #15185d;
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.premiacao__right {
    flex: 0 0 55%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.premiacao__selo {
    flex: 0 0 40%;
    margin-bottom: 40px;
    text-align: center;
}

.premiacao__selo h2 {
    font-size: 18px;
    font-weight: 900;
    margin-top: 6px;
    color: #15185d;
    margin-bottom: 8px;
}

/************************ PRODUTOS ****************************/
.produtos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.produto {
    flex: 0 0 33%;
    padding: 10px;
    margin-bottom: 20px;
    display: block;
    text-decoration: none;
    color: black;
}

.produto:hover {
    transform: scale(1.1)
}

.produto img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.produto h2 {
    font-weight: 900;
    text-align: center;
    margin-top: 20px;
}

.page--produto {
    position: relative;
}

.produto__bg {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
}

.produto__details {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.produto__left {
    flex: 0 0 33%;
}

.produto__left img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.produto__right {
    flex: 0 0 64%;
    line-height: 170%;
}

.produto__descricao {
    width: 400px;
    max-width: 100%;
}

.produto__premiacoes {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.produto__premiacoes-title {
    flex: 0 0 33%;
    font-size: 22px;
    font-weight: 900;
    text-align: center;
}

.produto__premiacoes-title img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.produto__selos {
    flex: 0 0 66%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.produto__selo {
    margin-bottom: 40px;
    flex: 0 0 44%;
}

.produto__selo img {
    float: left;
    width: 80px;
    height: auto;
    margin-right: 20px;
}

.produto__selo h2 {
    color: #15185d;
    font-size: 18px;
    font-weight: 900
}

/************************ ONDE COMPRAR ****************************/

.page--onde-comprar {
    padding-bottom: 0;
}

.onde-comprar__mapa {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3)
}

.onde-comprar__item {
    display: flex;
    margin-bottom: 44px;
    justify-content: space-between
}

.onde-comprar__thumb {
    flex: 0 0 47%;
}

.onde-comprar__thumb img {
    display: block;
    width: 100%;
}

.onde-comprar__info {
    flex: 0 0 47%;
}

.onde-comprar__info h2 {
    color: #15185d;
    font-weight: 900;
    margin-bottom: 20px;
}

.contato {
    display: flex;
    justify-content: space-between;
}

.contato__left,
.contato__right {
    flex: 0 0 49%;
}

/************************ NOTICIAS ****************************/
.noticia {
    display: flex;
    margin-bottom: 50px
}

.noticia__thumb img {
    display: block;
    width: 100%;
}

.noticia__thumb {
    flex: 0 0 33%;
    max-width: 33%;
}

.noticia__info {
    flex: 0 0 50%;
    padding-left: 50px;
}

.noticia__date {
    font-size: 18px;
    font-weight: 900;
}

.noticia__title {
    font-size: 22px;
    font-weight: 900;
    color: #15185d;
    margin-top: 15px;
    margin-bottom: 15px;
}

.noticia__share {
    margin-bottom: 20px;
}

.noticia__share a {
    padding: 10px;
    color: black;
    text-decoration: none;
}

.noticia--interna {
    justify-content: space-between;
}

.noticia--interna .noticia__info {
    padding-left: 0;
    flex: 0 0 55%;
}

.noticia--interna .noticia__thumb {
    max-width: 40%;
    flex: 0 0 40%;
}

.noticia--interna .noticia__thumb img {
    margin-bottom: 20px;
}

.noticia__text {
    line-height: 160%;
    margin-bottom: 20px;
}


@media(max-width: 1000px) {
    body {
        padding-top: 100px;
    }

    .header__open {
        display: block;
    }

    .header__nav {
        display: none;
    }

    .header {
        height: 100px;
    }

    .header__open {
        color: white;
        font-size: 27px;
        padding: 20px;
        margin-right: -20px;
    }

    .header__logo {
        width: 200px;
    }
    .header.sticky .header__logo--sticky {
        width: 100px
    }
    .header__nav {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: black;
        flex-direction: column;
        justify-content: center;
        z-index: 20000;
    }
    .header__nav.active {
        display: flex;
    }
    .header__menu {
        display: block;
        text-align: center;
    }
    .header__submenu {
        position: relative;
        display: none;
        top: 10px;
        pointer-events: initial;
    }
    .header__menu:hover .header__submenu {
        display: block;
        pointer-events: initial
    }
    .header__close {
        position: absolute;
        left: 0;
        top: -10px;
        padding: 20px;
        color: white;
        display: block;
        font-size: 40px;
    }

    .vila {
        flex-wrap: wrap;
        padding-top: 100px;
    }

    .vila__left,
    .vila__right {
        flex: 0 0 100%
    }

    .vila__carousel {
        padding-top: 100px;
    }
    .vinicola__left {
        width: 100%;
    }
    .vinicola__right {
        width: 100%;
    }

    .vinicola__item .vinicola__item-text,
    .vinicola__item .vinicola__item-thumb {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .premiacao__left, .premiacao__right {
        flex: 0 0 100%;
    }
    .premiacao__left {
        margin-bottom: 40px
    }
    .premiacao {
        flex-wrap: wrap;
    }
    .produto {
        flex: 0 0 50%;
    }

    .produto__left {
        flex: 0 0 100%;
        margin-bottom: 50px;
    }
    .produto__descricao {
        width: 100%;
    }
    .produto__right {
        flex: 0 0 100%;
    }
    .produto__premiacoes-title {
        flex: 0 0 100%;
    }
    .produto__premiacoes {
        flex-wrap: wrap;
    }
    .produto__selos {
        flex: 0 0 100%;
        margin-top: 100px;
        text-align: center;
    }
    .produto__selo img {
        float: none;
        margin-bottom: 10px;
        margin-right: 0;
    }
    .produto__details {
        flex-wrap: wrap;
    }

    .onde-comprar__item {
        flex-wrap: wrap;
    }
    .onde-comprar__thumb {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }

    .contato {
        flex-wrap: wrap;
    }

    .contato__left, .contato__right {
        flex: 0 0 100%;
    }

    .noticia {
        flex-wrap: wrap;
    }
    .noticia__thumb {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .noticia__info {
        padding-left: 0;
        flex: 0 0 100%;
    }
    .noticia--interna {
        flex-direction: column-reverse;
    }
    .noticia--interna .noticia__info {
        flex: 0 0 100%;
    }
    .noticia--interna .noticia__thumb {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .excelencia__content {
        width: 100%;
    }

    .destaque .container {
        padding-top: 100px;
        flex-wrap: wrap;
    }

    .destaque__left,
    .destaque__right {
        flex: 0 0 100%;
    }

    .destaque-swiper__detail,
    .destaque-swiper__title--left,
    .destaque-swiper__title--right {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: 100%;
        text-align: center;
        font-size: 34px;
    }

    .destaque-swiper__detail {
        border-top: none;
        text-align: left
    }
    .destaque-swiper__content {
        position: relative
    }

    .destaque-swiper__img {
        margin-top: 50px;
        height: 450px;
    }

    .footer .container {
        flex-wrap: wrap;
    }

    .footer__left {
        flex: 0 0 100%;
        margin-bottom: 30px;
        text-align: center;
    }
    .footer__menu {
        width: 100%;
        flex: 0 0 100%;
    }
    .footer__right {
        flex-wrap: wrap;
        flex: 0 0 100%;
    }


}

.swiper-wrapper.disabled {
    transform: translate3d(0px, 0, 0) !important;
}
.swiper-pagination.disabled {
    display: none;
}